<!--
* @Author: tianzl
* @Date: 2023-11-09 14:03:19
* @LastEditors: tianzl
* @LastEditTime: 2023-11-09 14:03:19
* @Description: 试驾车操作轨迹
 -->
 <template>
  <div class="trips">
    <event-item v-for="trip in records" :key="trip.id" :trip="trip"/>
  </div>
 </template>
<script>
import EventItem from './event-item.vue'
export default {
  components:{ EventItem },
  props:{
    records:{
      type: Array,
      default:()=>[]
    }
  },
}
</script>