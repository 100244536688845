<template>
  <div class="car-detail">
    <!-- 基础信息 -->
    <div class="basic-info">
      <div class="info">
        <span class="label">VIN：</span>
        <span class="val">{{ itemData.vinCode }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('车牌：')}}</span>
        <span class="val">{{ itemData.carNumber }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('车系')}}：</span>
        <span class="val">{{ itemData.seriesName }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('车型')}}：</span>
        <span class="val">{{ itemData.modelName }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('外饰颜色：')}}</span>
        <span class="val">{{ itemData.exteriorColor }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('内饰颜色：')}}</span>
        <span class="val">{{ itemData.interiorColor }}</span>
      </div>
      <div class="info">
        <span class="label">{{$t('录入日期：')}}</span>
        <span class="val">{{ itemData.entryTime }}</span>
      </div>
    </div>
    <!-- 轨迹 -->
    <div class="trip">
      <div style="width: 120px;">
        <van-tabs v-model="type">
          <van-tab
            v-for="item in tabs"
            :key="item.value"
            :title="item.name"
            :name="item.value"
          ></van-tab>
        </van-tabs>  
      </div>
      <Trips v-if="type===0" ref="trips" :records="records"/>
    </div>
  </div>
  </template>
<script>
import Trips from './components/trips'
import CarManageServices from '@/services/carManageService.js'
export default {
  components:{ Trips },
  data(){
    return {
      itemData:{
        vinCode:'',
        carNumber:'',
        exteriorColor: '',
        interiorColor: '',
        entryTime: '',
      },
      type: 0,
      tabs:[
        {
          name: this.$t('试驾车占用轨迹'),
          value: 0,
        }
      ],
      records:[]
    }
  },
  mounted(){
    const { id,vinCode,carNumber } = this.$route.query
    this.itemData.id = id
    this.itemData.vinCode = vinCode
    this.itemData.carNumber = carNumber
    this.getTripsDetails()
  },
  methods:{
    async getTripsDetails(){
      const res = await CarManageServices.getCarTrips({ id:this.itemData.id })
      const { records=[],...query } = res || {}
      // this.itemData.exteriorColor = exteriorColor
      // this.itemData.interiorColor = interiorColor
      // this.itemData.entryTime = entryTime
      Object.assign(this.itemData,query)
      this.records = records
      console.log('res:',res)
    }
  }
}
</script>
    <style lang="less" scoped>
    .car-detail{
      padding: 16px;
      .basic-info,.trip{
        padding: 14px 12px;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
      }
      .basic-info{
        margin-bottom: 16px;
      }
    }
    .info {
      margin-bottom: 8px;
      display: flex;
    
      .label {
        min-width: 65px;
        color: rgba(13, 23, 26, 0.45);
        font-size: 13px;
      }
    
      .val {
        white-space: normal;
        flex: 1;
        min-width: 0;
        overflow-wrap: break-word;
        font-size: 13px;
    
      }
    
    }
    </style>
