<!--
* @Author: tianzl
* @Date: 2023-10-19 10:58:05
* @LastEditors: tianzl
* @LastEditTime: 2023-10-19 10:58:05
* @Description: 新建线索事件
 -->
 <template>
  <div class="event-item">
    <div class="follow-time">
      <div class="date">{{trip.createTime | getDate }}</div>
      <div class="right">
        <div class="month">
          <span v-if="isNotSameYear">{{trip.createTime | getYear }}</span>
          <span v-if="isNotSameYear">/</span>
          <span>{{trip.createTime | getMonth }}</span>
          <span v-if="!isNotSameYear">{{$t('月')}}</span>
        </div>
        <div class="time">{{trip.createTime | getTime }}</div>
      </div>
    </div>
    <div class="info">
      <span>
        <div>
          <span class="mr8 event-type">{{$t('试驾车占用')}}</span>
          <span class="event-type">{{ trip?.operateUserName||trip?.createBy }}</span>
        </div>
        <div >
          <span class="label mr8">{{ $t('占用时间') }}</span>
          <span class="value">{{trip.processStartTime  }} - {{ trip.processEndTime }}</span>
        </div>
        <div v-for="field in eventFieldList" :key="field.key">
          <span class="label mr8">{{ field.label }}</span>
          <span class="value">{{ trip[field.key] | getdectText(trip,field,$store) }}</span>
        </div>
      </span>
    </div>
  </div>
  </template>
<script>
import moment from 'moment'
    
export default {
      
  filters:{
    getDate(val){
      if (!val) return ''
      return val.split(' ')[0].split('-')[2]
    },
    getYear(val){
      if (!val) return ''
      return val.split(' ')[0].split('-')[0]
    },
    getMonth(val){
      if (!val) return ''
      return val.split(' ')[0].split('-')[1]
    },
    getTime(val){
      if (!val) return ''
      return val.split(' ')[1].substring(0,5)
    },
    getdectText(val,trip,field,store){
      // 不需要查字典
      if (!field.dictCode) return val
      // 没有值的情况
      if (!trip[field.key]) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        const obj= {}
        list.map(({ code, id, name }) => {
          obj[code] = {
            id,
            label: name,
            value: code,
          }
        })
        return obj
      }
      const options = getOptionsByDictType(field.dictCode)
      // 兼容多选
      if (trip[field.key].indexOf(',') !== -1){
        const vals = trip[field.key].split(',')
        const names = vals.map(code => {
          if (options[code]?.label){
            return options[code]?.label
          }
        })
        return names.join(',')
      } 
      // const theOne = options.find(item=>item.value === trip[field.key])
      return options[trip[field.key]]?.label || ''
    }
  },
  // mixins:[myMixin],
  props: {
    trip: {
      type: Object,
      default: () => { }
    }
  },
  data(){
    return {
      eventFieldList:[
        { key:'processResult',label:this.$t('占用原因'), dictCode: '2007' },
        { key:'processRemark',label:this.$t('占用说明') },
      ]
    }
  },
  computed:{
    isNotSameYear(){
      const year = moment(this.trip.createTime).format('YYYY')
      const theYear = new Date().getFullYear()
      return year != theYear
    }
  }
}
</script>
    <style scoped lang="less" >
    @import "~@/modules/clue/component/trips/style/common.less";
    </style>
    